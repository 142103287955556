

export const projects = [
    {
        title:"DORATAOSE GENERAL CONTRACTOR",
        image:"constructioncard.png",
        type:"Branding"
    },
    {
        title:"Totot traditional restaurant",
        image:"totot-4.png",
        type:"Web"
    },
    {
        title:"Customer services automation for Addis Ababa Housing Development Corporation ",
        image:"housing-3.png",
        type:"Web"
    },
]