import React from 'react'
import { services } from '../data/whatWe'
import {motion} from 'framer-motion'


function WhatWeDo() {
  return (
    <div  className="w-[100%] min-h-[500px]">
 
    <div className="w-[80%] mt-[50px] mx-auto flex flex-col max-lg1:w-[90%]">
      <motion.div
        className="flex flex-col"
        initial={{ opacity: 0, y: -50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }} 
        transition={{ duration: 0.6, delay: 0.2 }}
      >
        <span className="text-[64px] text-white Montserrat uppercase max-sm1:text-[40px]">
          What <span className="text-[40px] max-sm1:text-[24px]">WE</span>
        </span>
        <motion.div className="ml-[100px] mt-[-50px] max-sm1:mt-[-25px]">
          <span className="text-[128px] mt-[-50px] self-end text-[#C4A04D] Montserrat max-sm1:text-[64px]">
            DO
          </span>
        </motion.div>
      </motion.div>

      <div className="w-[100%] min-h-[300px] flex justify-center items-end gap-[30px] max-lg:flex-wrap max-sm1:mt-[30px]">
        {services?.map((service, index) => (
          <motion.div
            key={index}
            className="border border-[#C4A04D] py-2 px-6 flex flex-col justify-center items-center gap-[20px] rounded-tl-[20px] rounded-tr-[20px]"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              duration: 0.6,
              delay: 0.8 + index * 0.4, 
            }}
          >
            <h1 className="text-[#C4A04D] text-[32px] Montserrat text-center uppercase max-lg2:text-[24px]">
              {service.name}
            </h1>
            <div className="my-[10px] flex flex-col items-center gap-[30px]">
              {service?.items?.map((item, idx) => (
                <span
                  key={idx}
                  className="text-white text-center Montserrat max-lg2:text-[14px]"
                >
                  {item.name}
                </span>
              ))}
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  </div>
  )
}

export default WhatWeDo