import React from 'react'
import { FaCheck } from 'react-icons/fa'
import { motion } from 'framer-motion'

function LetsGetInTouch() {
  return (
    <div className="w-[100%] min-h-[400px] p-2 bg-[#202020]">
    <div className="w-[80%] mx-auto my-[50px] max-md2:w-[90%]">
     
      <motion.div
        className="w-[100%] flex justify-center items-center"
        initial={{ opacity: 0, y: -30 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, delay: 0.2 }}
      >
        <span className="uppercase text-white text-center text-[64px] max-lg:text-[40px] max-md1:text-[24px]">
          Lets get in touch
        </span>
      </motion.div>

  
      <motion.div
        className="w-[100%] my-[50px] mx-auto grid grid-cols-2 gap-[20px] max-md1:grid-cols-1 max-md1:my-[50px]"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, delay: 0.4 }}
      >
       
        <motion.div
          className="w-[50%] mx-auto col-span-2 flex flex-col items-center gap-[20px] text-white text-center max-lg:w-[70%] max-sm1:w-[90%]"
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.6 }}
        >
          <span className="uppercase text-[24px] text-center max-lg:text-[32px] max-md2:text-[18px]">
            Schedule a call
          </span>
          <p className="w-[85%] text-[16px] text-center text-gray-300 max-lg1:w-[100%] max-md2:text-[14px]">
            Pick a convenient time for an introductory call with a developer and designer.
            Then, give us some basic information about your business and we’ll
            confirm your appointment after you checkout.
          </p>

         
          <motion.div
            className="w-[80%] mt-[20px] mx-auto grid grid-cols-2 gap-[20px]"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.8 }}
          >
            <div className=" col-span-1 flex flex-col items-center gap-[20px] max-md2:text-[14px] max-sm1:col-span-1 max-sm1:items-start">
              <div className="flex items-center gap-[10px]">
                <FaCheck />
                <span>+251993801803</span>
              </div>
              <div className="flex items-center gap-[10px]">
                <FaCheck />
                <span>+251923165405</span>
              </div>
            </div>
            <div className="col-span-1 flex flex-col items-center gap-[20px] max-md2:text-[14px] max-sm1:col-span-2 max-sm1:items-start">
              <div className="flex items-center gap-[10px]">
                <FaCheck />
                <span>+251993517161</span>
              </div>
              <div className="flex items-center gap-[10px]">
                <FaCheck />
                <span>+251940285915</span>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </div>
  </div>
  )
}

export default LetsGetInTouch