import React from 'react'
import { motion } from 'framer-motion';
import { texts } from '../data/whatWe';



function HorizontalSlider() {
  return (
    <div id='services' className='bg-[#C4A04D] h-[100px]  flex items-center overflow-hidden whitespace-nowrap w-[100%] relative'>
        <motion.div
                className="inline-flex gap-[100px]"
                initial={{ x: '0%' }}
                animate={{ x: '-50%' }}
                transition={{
                    ease: 'linear',
                    duration: 40, 
                    repeat: Infinity,
                }}
            >
                {texts?.concat(texts)?.map((text, index) => (
                    <span key={index} className="text-[1.5rem] mr-[2rem] Montserrat uppercase max-lg2:text-[1rem]">{text}</span>
                ))}
            </motion.div>
    </div>
  )
}

export default HorizontalSlider