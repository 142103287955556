import React from 'react'
function AboutUs() {
  return (
    <div id="about" className="w-[100%]  min-h-[500px] mt-[100px] p-2 max-lg1:mt-[50px]">
        
            <div className="w-[80%] my-[20px] mx-auto flex flex-col items-center gap-[20px] max-lg1:w-[90%]">
       

        <div>
            <h1 className='text-[#C4A04D] text-[64px] font-bold Montserrat uppercase max-lg:text-[40px]'>About Us</h1>
        </div>

        <div className='w-[100%] mx-auto grid grid-cols-2 gap-[20px] max-lg:grid-cols-1'>
            <div className='col-span-1 w-[100%] my-[20px] flex flex-col justify-center gap-[20px] text-white max-lg1:text-[14px] max-lg1:text-center max-lg1:leading-[30px]'>
                <p>We are a dynamic team of young, inspired software developers and graphic designers based in Ethiopia, specializing in website design, development, brand strategy, and logo design. Our mission is to help businesses and government services navigate the digital world by delivering stunning and functional user experiences tailored to each client's unique needs.</p>
                <p>With a commitment to quality and a passion for innovation, we offer not only exceptional digital solutions but also consulting services to guide our clients in achieving their goals. Our talented team brings a fresh perspective and extensive expertise to every project, ensuring we exceed expectations and foster lasting success for all our clients.</p>
            </div>
            <div className='flex justify-center items-center max-lg:hidden'>
                <img src={require("../images/web.png")} alt=""/>
            </div>
             </div>
      </div>

    </div>
  )
}

export default AboutUs