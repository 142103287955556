export const services = [{
    id:"1",
    name:"Elevate your brand",
    items:[{
        id:"1",
        name:"Logo Design"
        
    },
    {
        id:"2",
        name:"Brand Identity Development",
    
    },
    {
        id:"3",
        name:"Packaging Design"
    }
]
},{
    id:"2",
    name:"Web & App Development",
    items:[
        {
            id:"1",
            name:"Website Development"
        },
        {
            id:"2",
            name:"Mobile Application Development"
        },
        {
            id:"3",
            name:"ERP Solutions",
        },
        {
            id:"4",
            name:"Complete Management System"
        },
        {
            id:"5",
            name:"Structured Ecommerce Online Store"
        }
    ]
},
{
    id:"3",
    name:"Boost your digital presence",
    items:[{
        id:"1",
        name:"Personalized E-Commerce Website",
    },{
        id:"2",
        name:"Data Analytics Integrated System"
    },
    {
        id:"3",
        name:"Responsive Design"
    },
    {
        id:"4",
        name:"User-centred Design",

    },
    {
        id:"5",
        name:"Brand consistency"
    },
    {
        id:"6",
        name:"Web Design"
    },
    {
        id:"7",
        name:"Search engine optimization"
    },
    {
        id:"8",
        name:"Scalability"
    }
]
}
]

export const texts = [
    'Brand consistency',
    'Scalability',
    'User centered design',
    'Analytics Integration',
    'Technologies',
    'Security features',
    'User Experience',
    'UI Design'
];