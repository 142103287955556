import React, { useState } from "react";
import {motion} from "framer-motion"
import { projects } from "../data/projects";

function Projects() {
  const [overlay,setOverlay] = useState(false);
 

  const handleImageClick = (index) => {
    setOverlay(index); 
  
  };


  return (
    <div id="portfolio" className="w-[100%] min-h-[500px] mt-[50px] p-2">
    <div className="w-[80%] my-[20px] mx-auto flex flex-col items-center gap-[20px]">
      <motion.div
        className="flex flex-col items-center gap-[20px] Montserrat"
        initial={{ opacity: 0, y: -30 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, delay: 0.2 }}
      >
        <h1 className="py-2 px-4 border border-[#C4A04D] rounded-[20px] text-white text-[12px] font-bold text-center">
          Our Portfolio
        </h1>
        <span className="text-[#C4A04D] font-bold text-[32px] uppercase text-center max-sm1:text-[24px]">
          Some of our latest projects
        </span>
      </motion.div>
    </div>
    <div className="w-[80%] mx-auto my-[50px] grid grid-cols-3 gap-[20px] max-lg:gap-[15px] max-lg:grid-cols-2 max-md2:grid-cols-1 max-md2:gap-[30px]">
      {projects?.map((project, index) => (
        <motion.div
          key={index}
          className="col-span-1 relative w-[100%] h-[100%] rounded-[20px] overflow-hidden cursor-pointer max-md2:w-[100%]"
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
          onMouseEnter={() => setOverlay(index)}
          onMouseLeave={() => setOverlay(null)}
        >
          {overlay === index && (
            <motion.div
              className="absolute top-0 left-0 w-[80%] h-[100%] bg-black z-[100] flex justify-center items-center rounded-[20px] max-md2:w-[100%]"
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.9 }}
              transition={{ duration: 0.5 }}
            >
              <span className="w-[90%] mx-auto uppercase text-[24px] text-white text-center">
                {project.title}
              </span>
            </motion.div>
          )}
          <img
            src={require(`../images/${project?.image}`)}
            onClick={() => handleImageClick(index)}
            alt=""
            className="w-[80%] h-[100%] rounded-[20px] duration-500 max-md2:w-[100%]"
          />
        </motion.div>
      ))}
    </div>
  </div>
  );
}

export default Projects;
