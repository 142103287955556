import { motion } from "framer-motion";
const  Hero = ()=> {
  return (
    <div className="w-[100%]">
    <div className="w-[80%] my-[50px] mx-auto grid grid-cols-3 gap-[20px] max-lg2:my-[20px] max-sm1:w-[90%]">
      <div className="col-span-2 flex flex-col justify-center max-lg1:col-span-3 max-lg1:items-center">
        <div className="flex flex-col">
          <motion.span
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="text-[200px] font-bold text-[#C4A04D] max-lg2:text-[150px] max-md2:text-[100px] max-sm1:text-[50px] max-sm1:text-center"
          >
            ESCALOS
          </motion.span>

          <motion.span
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.8 }}
            className="text-[64px] mt-[-40px] text-[#C4A04D] max-lg1:text-center max-lg2:text-[32px] max-md2:mt-[5px] max-sm1:text-[24px]"
          >
            WEB & DESIGN SOLUTIONS
          </motion.span>
        </div>

        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 1.4 }}
          className="w-[75%] my-[20px] max-lg:text-center max-md2:w-[100%] max"
        >
          <span className="text-[22px] text-center text-gray-200 max-lg2:text-[16px]">
            Successful design solves problems. By understanding your business, and your customers, we develop Website and Brands that deliver measurable results across all platforms.
          </span>
        </motion.div>
      </div>
      <motion.div
        initial={{ x: 100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.6, delay: 2.0 }} 
        className="col-span-1 flex justify-end max-lg1:hidden"
      >
      <img src={require("../images/lamp.png")} alt="" className="max-lg2:w-[200px]"/>
      </motion.div>
    </div>
  </div>
  )
}

export default Hero