import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import HorizontalSlider from "./components/HorizontalSlider";
import LetsGetInTouch from "./components/LetsGetInTouch";
import Navbar from "./components/Navbar";
import Projects from "./components/Projects";
import WhatWeDo from "./components/WhatWeDo";

function App() {
  return (
    <div className="w-[100%]  min-h-[100vh] bg-[#1D2E2E]">
      <Navbar />
      <Hero />
      <AboutUs />
      <HorizontalSlider />
      <WhatWeDo />
      <Projects />
      <LetsGetInTouch />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
