import { useState } from "react";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { motion } from "framer-motion";
function Navbar() {
  const [responsiveNavbar, setResponsiveNavbar] = useState(false);

  return (
    <div className="w-[100%] py-2 shadow-md sticky top-0 bg-[#1D2E2E] z-[1000]">
      <div className="w-[80%] my-[20px] mx-auto flex justify-between items-center">
        <div className="">
          <img
            src={require("../images/ESCALOS.png")}
            alt=""
            className="max-[1500px]:w-[150px]"
          />
        </div>

        <div className="flex items-center gap-[40px] text-[#F5DD9A] Montserrat max-lg:text-[14px] max-md2:hidden">
          <a href="#about">About Us</a>
          <a href="#services">Our services</a>

          <a href="#portfolio">Portfolio</a>
          <a href="#contact">Contact Us</a>
        </div>
        <div
          onClick={() => setResponsiveNavbar(!responsiveNavbar)}
          className="hidden text-[40px] cursor-pointer text-[#F5DD9A] max-md2:block"
        >
          <HiOutlineMenuAlt2 />
        </div>
      </div>
      <motion.div
        initial={responsiveNavbar ? { height: 0 } : { height: 300 }}
        animate={responsiveNavbar ? { height: 300 } : { height: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full  max-md2:justify-center max-md2:items-center overflow-hidden  hidden max-md2:flex"
      >
        <motion.div
          initial={responsiveNavbar ? { opacity: 0 } : { opacity: 300 }}
          animate={responsiveNavbar ? { opacity: 300 } : { opacity: 0 }}
          className="w-80% h-full mx-auto gap-[30px] hidden text-[#F5DD9A] text-#F5DD9A Montserrat  max-lg:text-14px max-md2:flex max-md2:flex-col max-md2:justify-center max-md2:items-center"
        >
          <a href="#about">About Us</a>
          <a href="#services">Our services</a>

          <a href="#portfolio">Portfolio</a>
          <a href="#contact">Contact Us</a>
        </motion.div>
      </motion.div>
    </div>
  );
}

export default Navbar;
