import React from "react";
import { FaCopyright, FaPhoneAlt } from "react-icons/fa";
import {IoLinkSharp} from "react-icons/io5"

function Footer() {
  return (
    <div className="w-[100%] min-h-[500px]  bg-[#C4A04D] mt-[20px] flex items-center">
      <div className="w-[80%] mx-auto my-[50px] flex flex-col gap-[20px] max-lg2:w-[90%]">
        <div className="grid grid-cols-4 gap-[20px]">
          <div className="col-span-2 max-lg2:col-span-4">
            <img
              src={require("../images/Group 15.png")}
              alt=""
              className="max-lg:w-[40%]"
            />
            <span className="Montserrat text-[24px] text-[#1D2E2E] max-lg1:text-[18px]">
              WEB & DESIGN SOLUTIONS
            </span>
            <div className="my-[20px]">
              <p className="w-[90%] text-[16px] Montserrat max-lg1:w-[70%] max-lg:w-[90%] max-sm1:w-[100%]">
              We are a dynamic team of young, inspired software developers and graphic designers based in Ethiopia, specializing in website design, development, brand strategy, and logo design. Our mission is to help businesses and government services navigate the digital world by delivering stunning and functional user experiences tailored to each client's unique needs.
              </p>
            </div>
          </div>
          <div className="col-span-1 max-lg2:col-span-2 max-sm1:col-span-4">
            <div className="flex items-center gap-[10px] text-[24px] max-lg2:text-[18px]">
              <FaPhoneAlt />
              <span className="Montserrat font-bold">CONTACT US</span>
            </div>
            <div className="text-[16px] ml-5 my-[30px] flex flex-col gap-[20px] Montserrat max-lg2:text-[16px]">
              <span>+251993801803</span>
              <span>+251923165405</span>
              <span>+251993517161</span>
              <span>+251940285915</span>
            </div>
          </div>
          <div className="col-span-1 max-lg2:col-span-2 max-sm1:col-span-4">
            <div className="flex items-center gap-[10px] text-[24px] max-lg2:text-[18px]">
            <IoLinkSharp />
              <span className="Montserrat font-bold">QUICK LINKS</span>
            </div>
            <div className="text-[16px] ml-5 my-[30px] flex flex-col gap-[20px] Montserrat  max-lg2:text-[16px]">
              <a href="#services" className="hover:underline">
                Our services
              </a>
              <a href="/" className="hover:underline">
                About Us
              </a>
              <a href="#portfolio" className="hover:underline">
                Portfolio
              </a>
              <a href="#contact" className="hover:underline">
                Contact Us
              </a>
            </div>
          </div>
      
        </div>
        <div className="w-[100%] h-[1px] bg-[#333333]" />
        <div className="w-[100%] mt-[20px] flex justify-center items-center">
          <span className="text-[14px] flex items-center gap-[5px] max-lg2:text-[14px] Montserrat">
            Copyright <FaCopyright /> 2024{" "}
            <span className="font-bold">ESCALOS</span>. All Rights Reserved.
          </span>
        </div>
      </div>
    </div>
  );
}

export default Footer;
