import React, { useState } from 'react'
import { FaTelegram, FaYoutube} from 'react-icons/fa'
import {AiFillTikTok} from "react-icons/ai"
import {RiInstagramFill} from 'react-icons/ri'
import emailjs from "emailjs-com";
import Loading from './Loading';
import {toast, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

function ContactUs() {
  const [isChecked, setIsChecked] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        message: "",
      });
    const [loading,setLoading] = useState(false);

  

    const handleCheckboxChange = () => {
      setIsChecked((prevChecked) => !prevChecked);
    };

      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
    
      const sendEmail =async (e) => {
        e.preventDefault();

   try {
    if(!formData.name){
      toast.error("Please provide your name.")
      
    }
    if(!formData.phone){
      toast.error("Please provide your phone.")
    }
    
    setLoading(true)
    await emailjs.send(
      process.env.REACT_APP_SERVICEID,
      process.env.REACT_APP_TEMPLATEID,
      formData,
      process.env.REACT_APP_USERID
    )
      .then(
        () => {
          
          setLoading(false)
          setFormData({
            name: "",
            phone: "",
            message: "",
          })
          toast.success("Your message has been sent successfully. We will be in touch with you shortly.")
     
        },
        
      ).catch(()=>{
        setLoading(false)
        toast.error("An error occurred while sending your message. Please try again later.")
      });
    
   } catch (error) {
    setLoading(false)
    toast.error("An error occurred while sending your message. Please try again later.")
    
   }
      };
    
  return (
    <div  className="w-[100%] min-h-[600px]">
       <div className={"z-[1000]"}>
      <ToastContainer theme='light' limit={1}/>
      </div>
    <div className="w-[40%] min-h-[600px] p-2 my-[30px] mx-auto bg-white rounded-[20px] max-lg2:w-[50%] max-md2:w-[90%]">
      <form onSubmit={sendEmail} className="w-[100%]">
        <div className="w-[100%] my-[20px] mx-auto flex flex-col justify-center items-center">
          <h1 id='contact' className="text-[40px] Montserrat max-lg2:text-[32px] max-md2:text-[24px] text-center">
            Let's Discuss What's Next
          </h1>
          <p className="text-[18px] Montserrat mt-[20px] max-lg2:text-[14px]  max-lg2:w-[80%] text-center">
            Have a project or a question? we'd love to hear from you.
          </p>
          <p className="Roboto mt-[24px] max-lg2:text-[14px] max-lg2:w-[80%] text-center">
            We'll use this information on all future communications.
          </p>
        </div>
        <div className="w-[80%] my-[50px] mx-auto flex flex-col gap-[30px] max-lg2:w-[80%] max-md2:w-[90%]">
          <div className="flex flex-col gap-[20px]">
            <span className="text-[16px] font-bold Montserrat max-lg2:text-[14px]">
              What's Your Name?
            </span>
            <input
              className="border border-gray-200 shadow-lg py-4 px-2 outline-none rounded-[10px] Montserrat text-[14px] max-lg2:text-[12px]"
              type="text"
              name="name"
              placeholder="Type your answer here..."
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="flex flex-col gap-[20px]">
            <span className="text-[16px] font-bold Montserrat max-lg2:text-[14px]">
              Could We Grab Your Phone Number As Well?
            </span>
            <input
              className="border border-gray-200 shadow-lg py-4 px-2 outline-none rounded-[10px] Montserrat text-[14px] max-lg2:text-[12px]"
              type="text"
              name="phone"
              placeholder="Type your answer here..."
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div className='flex items-center gap-[10px]'>
            <input type='checkbox' onChange={handleCheckboxChange} checked={isChecked}/>
            <span className='font-bold Montserrat max-lg2:text-[14px]'>Is there any additional information you'd like to provide?</span>
          </div>
          {isChecked && (
        <div className="flex flex-col gap-[20px] mt-4">
          
          <textarea
            rows={10}
            className="border border-gray-200 shadow-lg py-4 px-2 outline-none rounded-[10px] Montserrat max-lg2:text-[14px]"
            placeholder="Type your answer here..."
            name="message"
            value={formData.message}
            onChange={handleChange}
          ></textarea>
        </div>
      )}
          <div className="w-[100%] flex justify-between items-center gap-[20px]">
       
            <div>
             {loading ? <Loading addtionalStyle={"flex justify-end items-center"} />:  <button
              type="submit"
              className="bg-[#1D2E2E] py-3 px-4  text-white rounded-[10px] Montserrat text-[14px] max-lg2:text-[12px]"
            >
              Send form
            </button>}
          

            </div>
            

          
          
          </div>
        </div>
      </form>
    </div>
    <div className="w-[80%] mx-auto my-[20px] flex justify-center items-center gap-[20px]">
      <a href="https://www.instagram.com/escalos1624?igsh=cDY2N2p1a3hleWFk&utm_source=qr" target='_blank' rel="noreferrer">
        <RiInstagramFill  className="p-2 text-white text-[40px] rounded-full hover:text-[#C4A04D]" />
      </a>
      <a href="https://www.tiktok.com/@escalos1624" target='_blank' rel="noreferrer">
        <AiFillTikTok  className="p-2 text-white  text-[40px] rounded-[5px] hover:bg-[#C4A04D]" />
      </a>
      <a href="https://www.youtube.com/@ESCALOS" target='_blank' rel="noreferrer">
        <FaYoutube className="p-2 text-white text-[40px] rounded-full hover:text-[#C4A04D]" />
      </a>
      <a href='https://t.me/escalos1624' target='_blank' rel="noreferrer">
        <FaTelegram className="p-2 text-white text-[40px] rounded-full hover:text-[#C4A04D]" />
      </a>
    </div>
  </div>
  )
}

export default ContactUs